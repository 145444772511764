import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import log from 'chameleon/ui-stack/utilities/log';
import _, { isEmpty } from 'lodash';
import cn from 'classnames';
import { Table as MaterialTable } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import { apMuiTheme } from 'legacy/packages/ft-cb';
import { ThemeProvider } from '@material-ui/core/styles';
import './style.scss';

function isWrapped(value, tagName) {
  return value && value.type === tagName;
}

function mininumCol(col) {
  return typeof col === 'string' ? { id: col } : col;
}

// Applies to the progress tab only: row = assignment, row.item = assessment.
const IsSecure = (row) => row && row.item && row.is_secure;

const stickyTableCellName = 'sticky-table-cell';

const getStickyCellClassName = (columnId, stickyGroupIds = []) =>
  stickyGroupIds.some(
    (stickyGroupId, stickyGroupIndex, ids) =>
      stickyGroupId === columnId && ids.length - 1 === stickyGroupIndex,
  )
    ? `${stickyTableCellName} last-sticky-cell`
    : stickyGroupIds.some((stickyGroupId) => stickyGroupId === columnId)
      ? `${stickyTableCellName}`
      : '';

const THead = (props) => {
  const cols = props.cols.map(mininumCol);
  const { groups, stickyColumnIds, stickyGroupIds } = props;

  let groupedCols = [];
  let displayedGroups = [];

  return (
    <TableHead>
      <TableRow>
        {cols.map((col) => {
          const {
            id,
            label,
            head,
            groupId,
            applyCustomProps,
            headerClassName,
            headerProps = {},
          } = col;

          let otherProps = {};
          if (applyCustomProps) {
            otherProps = {
              ...otherProps,
              ...applyCustomProps(col),
            };
          }

          let cell = head ? head() : label;
          const stickyCellClassName = getStickyCellClassName(
            groupId,
            stickyGroupIds,
          );

          cell = cell === undefined ? id : cell;
          const group = (groupId && _.find(groups, { id: groupId })) || null;
          const rowSpan = groups && groups.length ? { rowSpan: 2 } : {};

          let th = isWrapped(cell, 'th') || (
            <TableCell
              key={id}
              className={cn(`th_${id}`, headerClassName)}
              {...rowSpan}
              {...otherProps}
              {...headerProps}
            >
              {cell}
            </TableCell>
          );

          if (group) {
            groupedCols.push({ id, cell });
            if (displayedGroups.includes(group.id)) {
              th = null;
            } else {
              const colsInGroup = _.filter(cols, { groupId });
              th = (
                <TableCell
                  key={`${id}${group.id}`}
                  className={`col-group ${stickyCellClassName}`}
                  colSpan={colsInGroup.length}
                  scope="colgroup"
                >
                  <span>{group.label}</span>
                </TableCell>
              );
              displayedGroups.push(groupId);
            }
          }
          return th;
        })}
      </TableRow>
      {/* Column headers per grouping */}
      {!!groupedCols.length && (
        <TableRow>
          {groupedCols.map((cellObj, index) => {
            const stickyCellClassName = getStickyCellClassName(
              cellObj.id,
              stickyColumnIds,
            );

            return (
              isWrapped(cellObj.cell, 'th') || (
                <TableCell
                  key={index}
                  className={`col-child th_${index} ${stickyCellClassName}`}
                >
                  {cellObj.cell}
                </TableCell>
              )
            );
          })}
        </TableRow>
      )}
    </TableHead>
  );
};
THead.displayName = 'THead';

const TBody = (props) => {
  const { enableLdbFeatures, stickyColumnIds, rowClassName = '' } = props;

  const objectRows = (cols, makeKey) => (row) => (
    <TableRow
      key={`${makeKey(row)}`}
      className={cn(
        { 'secure-assessment': enableLdbFeatures && IsSecure(row) },
        row.className,
        rowClassName,
      )}
    >
      {cols.map(
        (
          {
            id,
            body,
            emptyCellClassName = '',
            applyCustomProps,
            hideCell,
            className,
            useFlexDisplay = false,
            style,
          },
          index,
        ) => {
          const stickyCellClassName = getStickyCellClassName(
            id,
            stickyColumnIds,
          );
          let otherProps = {
            component: index === 0 ? 'th' : 'td',
            scope: index === 0 ? 'row' : undefined,
          };
          if (applyCustomProps) {
            otherProps = {
              ...otherProps,
              ...applyCustomProps(row),
            };
          }
          const showCell = !hideCell || !hideCell(row);
          try {
            const cell = body ? body(row) : row[id];
            const showNotSetLabel =
              cell === String.fromCharCode(45) ||
              cell === String.fromCharCode(8212) ||
              cell === String.fromCharCode(0x2014);
            return (
              isWrapped(cell, 'td') ||
              (showCell && (
                <TableCell
                  key={`${id}${index}`}
                  className={cn(className, stickyCellClassName, {
                    [emptyCellClassName]: !Boolean(cell),
                  })}
                  style={style}
                  {...otherProps}
                  // eslint-disable-next-line jsx-a11y/aria-role
                  role={null}
                >
                  {showNotSetLabel && <span className="sr-only">Not set</span>}
                  {useFlexDisplay ? (
                    <span className="flex items-center justify-center">
                      {cell}
                    </span>
                  ) : (
                    <span
                      className="grid grid-flow-col justify-start items-center gap-x-2"
                      aria-hidden={showNotSetLabel}
                    >
                      {cell}
                    </span>
                  )}
                </TableCell>
              ))
            );
          } catch (e) {
            log('error', e);
            return (
              <TableCell
                key={`${id}${index}`}
                className={cn(stickyCellClassName, emptyCellClassName)}
                {...otherProps}
              />
            );
          }
        },
      )}
    </TableRow>
  );

  const arrayRows = () => (row, index) => (
    <TableRow key={index}>
      {row.map((c, i) => (
        <TableCell key={i}>{c}</TableCell>
      ))}
    </TableRow>
  );

  const { rows, cols, keyCol, keyFunc } = props;
  const makeKey = keyFunc || (keyCol ? (r) => r[keyCol] : (r) => r.id);

  const rowMapper = cols
    ? objectRows(cols.map(mininumCol), makeKey)
    : arrayRows();

  return <TableBody>{rows.map(rowMapper)}</TableBody>;
};
TBody.displayName = 'TBody';

const TFoot = () => {
  const cols = this.props.cols.map(mininumCol);
  return (
    <TableFooter>
      <TableRow>
        {cols.map(({ id, foot, label }) => {
          const cell = foot ? foot() : label || id;
          return (
            isWrapped(cell, 'td') || <TableCell key={id}>{cell}</TableCell>
          );
        })}
      </TableRow>
    </TableFooter>
  );
};
TFoot.displayName = 'TFoot';

const Table = (props) => {
  const {
    type,
    cols,
    colGroup,
    groups,
    children,
    stickyColumnIds,
    stickyGroupIds,
    ariaLabel,
  } = props;
  const isSticky = !isEmpty(stickyColumnIds) || !isEmpty(stickyGroupIds);

  useEffect(() => {
    if (isSticky) {
      // accumulate the widths of all the sticky table cells of the first row, and then set the width of the duplicated table.
      // this establishes the sticky column
      const width = Array.from(
        document
          .querySelector('.table--lined tr')
          .querySelectorAll(`.${stickyTableCellName}`),
      ).reduce(
        (totalWidth, node) => totalWidth + node.getBoundingClientRect().width,
        0,
      );
      document.querySelector('.sticky-duplicated-table-wrapper').style.width =
        `${width + 5}px`;

      document.querySelector(
        '.sticky-duplicated-table-wrapper table',
      ).style.width = `${
        document.querySelector('.sticky-table-wrapper').getBoundingClientRect()
          .width
      }px`;
      window.addEventListener('resize', () => {
        document.querySelector(
          '.sticky-duplicated-table-wrapper table',
        ).style.width = `${
          document
            .querySelector('.sticky-table-wrapper')
            .getBoundingClientRect().width
        }px`;
      });
    }
  }, []);

  return (
    <ThemeProvider theme={apMuiTheme}>
      <div
        className={cn('table-wrap', props.className, {
          'sticky-wrapper': isSticky,
        })}
      >
        {!isSticky ? (
          <MaterialTable
            className={type || 'table--lined'}
            aria-label={ariaLabel}
          >
            {colGroup}
            <THead
              cols={cols}
              groups={groups}
              stickyColumnIds={stickyColumnIds}
              stickyGroupIds={stickyGroupIds}
            />
            {children || <TBody {...props} />}
          </MaterialTable>
        ) : (
          <Fragment>
            <div className="sticky-table-wrapper">
              <MaterialTable
                className={type || 'table--lined'}
                aria-label={ariaLabel}
              >
                {colGroup}
                <THead
                  cols={cols}
                  groups={groups}
                  stickyColumnIds={stickyColumnIds}
                  stickyGroupIds={stickyGroupIds}
                />
                {children || <TBody {...props} />}
              </MaterialTable>
            </div>
            <div className="sticky-duplicated-table-wrapper" aria-hidden="true">
              <div className="sticky-duplicated-table-wrapper__inner">
                <MaterialTable className={type || 'table--lined'}>
                  {colGroup}
                  <THead
                    cols={cols}
                    groups={groups}
                    stickyColumnIds={stickyColumnIds}
                    stickyGroupIds={stickyGroupIds}
                  />
                  {children || <TBody {...props} />}
                </MaterialTable>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </ThemeProvider>
  );
};

Table.displayName = 'Table';

Table.propTypes = {
  enableLdbFeatures: PropTypes.bool,
  stickyColumnIds: PropTypes.array,
  stickyGroupIds: PropTypes.array,
};

Table.defaultProps = {
  enableLdbFeatures: false,
  stickyColumnIds: [],
  stickyGroupIds: [],
};

export default Table;
