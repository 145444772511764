import { SUBJECT_TYPE_VIEW } from 'chameleon/src/constants/config';

export const getScoredSubmissions = (progress) => {
  const scored = progress.scored || 0;
  const studentScored = progress.studentScored || progress.student_scored || 0;
  const teacherAndStudentScored =
    progress.teacherAndStudentScored ||
    progress.teacher_and_student_scored ||
    0;
  const complete = progress.complete || 0;
  return complete + scored + studentScored + teacherAndStudentScored;
};

export const getTotalSubmissions = (progress) => {
  const notStarted = progress.notStarted || progress.not_started || 0;
  const started = progress.started || 0;
  const submitted = progress.submitted || 0;
  const scoring = progress.scoring || 0;
  const scored = progress.scored || 0;
  const watched = progress.watched || 0;
  const studentScored = progress.studentScored || progress.student_scored || 0;
  const teacherAndStudentScored =
    progress.teacherAndStudentScored ||
    progress.teacher_and_student_scored ||
    0;
  const complete = progress.complete || 0;
  const readyToScore = progress.readyToScore || progress.ready_to_score || 0;

  return (
    notStarted +
    started +
    submitted +
    scoring +
    scored +
    watched +
    studentScored +
    teacherAndStudentScored +
    complete +
    readyToScore
  );
};

export const getFinishedPercent = (progress) => {
  const totalSubmissions = getTotalSubmissions(progress);
  const scoredSubmissions = getScoredSubmissions(progress);

  if (totalSubmissions === 0) {
    return 0;
  }
  return (scoredSubmissions / totalSubmissions) * 100;
};

export const findQBSubject = (currentTeacherSubjects, masterSubjectId) =>
  currentTeacherSubjects &&
  currentTeacherSubjects.find(
    (subject) =>
      (subject.type === SUBJECT_TYPE_VIEW.QUESTION_BANK ||
        subject.type === SUBJECT_TYPE_VIEW.PREAP) &&
      parseInt(subject.masterSubjectId, 10) === parseInt(masterSubjectId, 10),
  );

export const computeAssignmentsToScore = (progress) =>
  progress.submitted + progress.scoring + progress.readyToScore;

const sanitizeProgressForAssignmentsReadyToScore = (progress) => {
  const { ready_to_score, readyToScore, ...rest } = progress;
  if (readyToScore === undefined) {
    return { ...rest, readyToScore: ready_to_score };
  }
  return progress;
};

export const computeAssignmentsReadyToScore = (progress) => {
  const sanitizedProgress =
    sanitizeProgressForAssignmentsReadyToScore(progress);
  return sanitizedProgress.scoring + sanitizedProgress.readyToScore;
};
