import React, { lazy, Suspense, useEffect, useReducer } from 'react';
import get from 'lodash/get';
import SplitIOHandler from 'chameleon/ui-stack/utilities/split-io-handler';
import { LoadingSpinner } from 'legacy/packages/ft-cb';
import { PREAP_SUBJECTS } from 'chameleon/src/constants/subjects';
import Context, { TYPES, reducer, initialValue } from './state/Context';

const LegacyApp = lazy(() => import('client/src/index'));
const ChameleonApp = lazy(() => import('chameleon/src/Root'));

const PREAP_CHAMELEON_APP = 'preap_chameleon_app';

const fetchFlags = (keys, uid, callback, listenCallback) => {
  const flagHandler = new SplitIOHandler({
    key: uid || '1',
    authorizationKey: SPLITIO_API_KEY,
    splitName: keys,
    listenCallback: (treatment) => {
      if (treatment) {
        listenCallback(treatment);
      } else {
        throw new Error('P2 Flag error');
      }
    },
    callback: (treatment) => {
      if (treatment) {
        callback(treatment);
      } else {
        throw new Error('P2 Flag error');
      }
    },
  });
  flagHandler.invoke();
};

const AppLoader = () => {
  const reducerProvider = useReducer(reducer, initialValue);
  const [{ useChameleonApp }, dispatch] = reducerProvider;

  useEffect(() => {
    const callback = (treatments) => {
      const subjectId = parseInt(window.location.pathname.split('/')?.[1], 10);
      const isPreApSubject = PREAP_SUBJECTS.includes(subjectId);
      const isPreApChameleon = treatments.preap_chameleon_app === 'on';

      dispatch({
        type: TYPES.SET_USE_CHAMELEON,
        payload: {
          isPreApSubject,
          preApChameleonFlag: isPreApChameleon,
        },
      });
    };
    fetchFlags(
      [PREAP_CHAMELEON_APP],
      get(window, 'current_user.initId', -1),
      callback,
      callback,
    );
  }, []);

  const isUseChameleonAppEqualsNull = useChameleonApp === null;

  useEffect(() => {
    const html = document.querySelector('html');
    const body = document.querySelector('body');

    if (!useChameleonApp && !isUseChameleonAppEqualsNull) {
      html.classList.remove('chameleon', 'chameleon-html');
      body.classList.remove('chameleon-body');
      html.classList.add('legacy');
    } else {
      html.classList.add('chameleon', 'chameleon-html');
      body.classList.add('chameleon-body');
      html.classList.remove('legacy');
    }
  }, [useChameleonApp]);

  if (isUseChameleonAppEqualsNull) {
    return null;
  }
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Context.Provider value={reducerProvider}>
        {useChameleonApp ? (
          <ChameleonApp key="chameleon" />
        ) : (
          <LegacyApp key="legacy" />
        )}
      </Context.Provider>
    </Suspense>
  );
};

export default AppLoader;
