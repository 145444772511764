import React from 'react';

const TYPES = {
  SET_USE_CHAMELEON: 'set-use-chameleon',
};

const { SET_USE_CHAMELEON } = TYPES;

const PREAP_CHAMELEON_EP_CODE = 27;

window.enablePreApChameleon = () => {
  window.sessionStorage.setItem('PREAP_CHAMELEON', 'true');
  window.location.reload();
};

window.disablePreApChameleon = () => {
  window.sessionStorage.setItem('PREAP_CHAMELEON', 'false');
  window.location.reload();
};

/**
 * Determines whether the Chameleon feature should be used based on the provided parameters.
 *
 * @param {string} epCode
 * @param {boolean} isPreApSubject
 * @param {boolean} preApChameleonFlag - Flag to force Chameleon usage in PreAP.
 * @returns {boolean} - Returns true if Chameleon feature should be used, otherwise false.
 */
export const shouldUseChameleon = (
  epCode,
  isPreApSubject = false,
  preApChameleonFlag = false,
) => {
  return isPreApSubject
    ? preApChameleonFlag || parseInt(epCode, 10) >= PREAP_CHAMELEON_EP_CODE
    : true;
};

const reducer = (state, action) => {
  const { type, payload } = action;
  const { isPreApSubject, preApChameleonFlag, epCode } = payload || {};

  let preApChameleonSessionStorage = null;

  try {
    preApChameleonSessionStorage = JSON.parse(
      window.sessionStorage.getItem('PREAP_CHAMELEON'),
    );
  } catch (e) {
    // ignore error
  }

  if (isPreApSubject && preApChameleonSessionStorage !== null) {
    return {
      ...state,
      useChameleonApp: preApChameleonSessionStorage,
    };
  }

  switch (type) {
    case SET_USE_CHAMELEON:
      return {
        ...state,
        useChameleonApp: shouldUseChameleon(
          epCode,
          isPreApSubject,
          preApChameleonFlag,
        ),
      };
    default:
      throw Error('action type is not supported');
  }
};

const initialValue = {
  useChameleonApp: null,
};

const Context = React.createContext([]);

export default Context;
export { TYPES, initialValue, reducer, PREAP_CHAMELEON_EP_CODE };
