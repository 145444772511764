import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { withRouter } from 'react-router';
import { MixedLink, Icon, Tabs } from '../../index';
import { AP_CALCULUS_BC } from 'chameleon/src/constants/subjects';

import './index.scss';

class PrimaryNav extends PureComponent {
  static propTypes = {
    data: PropTypes.array.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    hideExpiredContent: PropTypes.bool,
    showGlobalNav: PropTypes.bool,
  };

  static defaultProps = {
    data: [],
    hideExpiredContent: false,
    id: 0,
  };

  constructor(props) {
    super(props);

    const path = this.getPath();
    this.state = {
      active: (path === 'assignments' && 'ASSESSMENTS') || path || 'HOME',
    };

    this.changeActive = this.changeActive.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setActive(nextProps.routes[3].path);
  }

  getPath() {
    const path = this.props.routes[3].path.match(/(\w+)/);
    return path[0];
  }

  componentDidMount() {
    this.setActive(this.props.routes[3].path);
  }

  setActive(path) {
    switch (path) {
      case 'assessments':
        if (this.props.userData.isStudent) {
          this.setState({ active: 'ASSESSMENTS' });
        } else {
          this.setState({ active: 'PROGRESS CHECKS' });
        }
        break;
      case 'dashboard':
        this.setState({ active: 'PROGRESS DASHBOARD' });
        break;
      case 'question_bank':
        this.setState({ active: 'QUESTION BANK' });
        break;
      case 'classes':
        this.setState({ active: 'MY CLASSES' });
        break;
      case 'usage':
        this.setState({ active: 'USAGE' });
        break;
      default:
        this.setState({ active: 'HOME' });
        break;
    }
  }

  changeActive(active) {
    this.setState({ active });
  }

  authenticatedPath = (nextUrl, subject) => {
    const accountAccessToken = window.localStorage.getItem(
      'account_access_token',
    );
    const accountRefreshToken = window.localStorage.getItem(
      'account_refresh_token',
    );
    const expires = window.localStorage.getItem('expires');

    /**
     * Done for https://academicmerit.atlassian.net/browse/FY-18711
     * Professional Learning resolves calculus to 25 (Calculus AB) whether or not
     * we are from AB/ BC. This is to ensure that if we come from Calc BC, we retrieve
     * the correct subject data from the PL logic.
     */
    if (subject && parseInt(subject, 10) === AP_CALCULUS_BC) {
      nextUrl += '?calcbc=1';
    }

    const params = `?next=${encodeURIComponent(
      nextUrl,
    )}&jwt=${accountAccessToken}&refresh=${accountRefreshToken}&expires=${expires}`;
    // Build temp a element to Extract URL
    const l = document.createElement('a');

    l.href = nextUrl;

    const redirectHost = `${l.protocol}//${l.hostname}${
      l.port ? `:${l.port}` : ''
    }`;
    const destPath = `${redirectHost}/n/auth/redirect/${params}`;

    return destPath;
  };

  render() {
    const { data, id, userData, hideExpiredContent, showGlobalNav, params } =
      this.props;
    const { isStudent, isTeacher, isCoordinator, isAdmin } = userData;
    const { active } = this.state;
    const { subject } = params || {};
    const redirectROS =
      (isTeacher || isCoordinator) && FEATUREFLAGS.myClasses.redirectRos;
    return (
      <div className="PrimaryNav">
        <Tabs style="bar">
          {!hideExpiredContent && (
            <MixedLink
              path={`${FYM_FRONTEND_URL}/${id}/home`}
              className={cn({ active: active === 'HOME' })}
              onClick={() => this.changeActive('home')}
            >
              <Icon name="home" size="1.143em" /> Home
            </MixedLink>
          )}
          {Object.keys(data).map((elemIndex, iterIndex) => {
            let navHref = elemIndex.href;
            let visible = true;
            const navItem = data[elemIndex];

            switch (navItem.navName.trim().toUpperCase()) {
              case 'ASSESSMENTS':
                navHref = !isStudent
                  ? navItem.navUrl
                  : `${navItem.navUrl}/assignments`;
                break;
              case 'PROGRESS CHECKS':
                navHref = navItem.navUrl;
                break;
              case 'PROGRESS DASHBOARD':
                navHref = navItem.navUrl;
                break;
              case 'QUESTION BANK':
                navHref = navItem.navUrl;
                break;
              case 'PROFESSIONAL LEARNING':
              case 'PROFESSIONAL LEARNING (OLD)':
                navHref = this.authenticatedPath(`${navItem.navUrl}`, subject);
                break;
              case 'MY CLASSES':
                navHref = navItem.navUrl;
                break;
              case 'USAGE':
                navHref = navItem.navUrl;
                break;
              default:
                navHref = '';
                break;
            }

            /* For teacher and coordinator, redirect to ROS. Admin (and student) do not */
            if (redirectROS && navItem.navName.toUpperCase() === 'MY CLASSES') {
              navHref = `${ROS_FRONTEND_URL}/courses`;
            }

            /* For p1i admin disable myClasses */
            if (isAdmin && navItem.navName.toUpperCase() === 'MY CLASSES') {
              visible = false;
            }
            /* disable for all users if feature flag enabled */
            if (
              showGlobalNav &&
              navItem.navName.toUpperCase() === 'MY CLASSES'
            ) {
              visible = false;
            }

            /* Home is rendered independently at the beggining of this method */
            if (navItem.navName.toUpperCase() === 'HOME') {
              visible = false;
            }

            if (visible) {
              return (
                <MixedLink
                  key={iterIndex}
                  path={navHref}
                  className={cn({
                    active: active === navItem.navName.toUpperCase(),
                  })}
                  onClick={() => this.changeActive(navItem.navName)}
                >
                  {navItem.navName}
                </MixedLink>
              );
            }
          })}
        </Tabs>
      </div>
    );
  }
}

export default withRouter(PrimaryNav);
