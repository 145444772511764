import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import uniqBy from 'lodash/uniqBy';
import { MixedLink } from '../../index';
import PopOver from 'chameleon/ui-stack/ui/base/popover';
import ButtonWithIcon from 'chameleon/ui-stack/ui/buttons/button-with-icon';
import Icon from 'chameleon/ui-stack/ui/base/icon';
import { TYPES } from 'legacy/src/utility/Filters/utils';

const SubjectSelector = (props) => {
  const {
    redirectPath = 'home',
    fymSubjects = [],
    onSubjectClick,
    userData,
    subject,
  } = props;
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState({});

  useEffect(() => {
    const {
      isStudent,
      isTeacher,
      isAdmin,
      isCoordinator,
      teacherSubjects,
      studentSubjects,
      adminSubjects,
    } = userData;
    const isAdminCoordinator = isAdmin || isCoordinator;
    if (isStudent) {
      setSubjects(studentSubjects);
    } else if (isTeacher) {
      setSubjects(teacherSubjects);
    } else if (isAdminCoordinator) {
      setSubjects(
        uniqBy(
          adminSubjects.map((item) => {
            const { masterSubjectId, name, subjectProgram } = item;
            return {
              id: masterSubjectId.toString(),
              name,
              subjectProgram,
            };
          }),
          'id',
        ),
      );
    }
  }, [subject]);

  useEffect(() => {
    if (subject && subjects.length) {
      const currentSubject = subjects.find(({ id }) => id === subject);
      // the FilterControls component will try to re-load details about the last group you were looking at on a few
      // different pages, this data is stored in local storage, but is persisting when changed in subject.
      window.localStorage.removeItem(TYPES.localStorageGroupFilterKey);
      setSelectedSubject(currentSubject);
    }
  }, [subject, subjects]);

  const { name, id: selectedSubjectId } = selectedSubject || {};

  if ((subjects || []).length === 1) {
    return <span className="px-4.6 h-12.6 flex items-center">{name}</span>;
  }

  return (
    <PopOver
      alwaysClose
      position="start"
      isNarrow
      trigger={(buttonProps, isOpen, ref) => (
        <ButtonWithIcon
          isSecondary
          iconAccessibility={{
            ariaHidden: true,
          }}
          iconOrientation="right"
          accessibility={{
            'aria-label': `Subject selector - ${name}`,
          }}
          ref={ref}
          icon="keyboard_arrow_down"
          {...buttonProps}
        >
          {name}
        </ButtonWithIcon>
      )}
    >
      <div>
        {subjects.map((subject, index) => {
          const { id } = subject;
          const isFymSubject = find(fymSubjects, {
            id,
          });

          let extraMixedLinkProps = {};
          if (onSubjectClick) {
            extraMixedLinkProps.onClick = (event) => onSubjectClick(event, id);
          }

          const path = isFymSubject
            ? `/${id}/${redirectPath}`
            : `${FYM_FRONTEND_URL}/${id}/home`;

          return (
            <div key={`${id}-${index}`}>
              <MixedLink
                className="px-4.6 h-12.6 text-blue-700 hover:bg-gray-200 whitespace-nowrap flex items-center"
                path={path}
                {...extraMixedLinkProps}
              >
                {subject.name}
                {id === selectedSubjectId ? (
                  <Icon
                    id="checkmark"
                    className="text-gray-600 ml-auto"
                    accessibility={{
                      title: 'Selected',
                    }}
                  />
                ) : null}
              </MixedLink>
            </div>
          );
        })}
      </div>
    </PopOver>
  );
};

SubjectSelector.displayName = 'SubjectSelector';

SubjectSelector.propTypes = {
  redirectPath: PropTypes.string,
  fymFrontEndUrl: PropTypes.string,
  fymSubjects: PropTypes.array,
  onSubjectClick: PropTypes.func,
  userData: PropTypes.object.isRequired,
  subject: PropTypes.string.isRequired,
};

SubjectSelector.defaultProps = {
  onSubjectClick: null,
};

export default SubjectSelector;
