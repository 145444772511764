/**
 * Even thought we can handle the popover interactions and positioning ourself, in our repo.
 * (the requirements being quite straight-forward). We would still end-up with a lot of code for maintenance,
 * especially for focus and unfocus elements, code tangled and hard to come back to make changes,
 * be it adding a feature or updating a11y requirements.
 *
 * So I purpose to use a community based positioning library called 'floating-ui'
 * which has roots in 'Pooper.js'.
 * Read more about it here: https://floating-ui.com/docs/motivation.
 */
import {
  offset,
  flip,
  shift,
  autoUpdate,
  useFloating,
  useInteractions,
  useRole,
  useDismiss,
  useHover,
  useId,
  size as applySize,
  useFloatingNodeId,
  FloatingPortal,
  useClick,
  safePolygon,
} from '@floating-ui/react';

import React, {
  useMemo,
  useState,
  useImperativeHandle,
  useEffect,
} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { omit } from 'lodash';
import isFunction from 'lodash/isFunction';

import PopoverContentElement from './PopoverContentElement';
import { getArrowPosition } from './utils';
import './index.scss';

const getPlacement = (position) => {
  if (['start', 'center', 'end'].includes(position))
    return `bottom-${position}`;
  return position || 'bottom-start';
};

/**
 * The Popover component
 *
 * Display disposable content behind a button or text component.
 * The children of this component are visible when a user clicked or focus[1]
 * on the `trigger element in props.
 * Correspondingly it's closed when the user clicks outside of the popover
 *
 * __[1]__: Focused using keyboard or other a11y interface.
 *
 *
 * The trigger element should be an interactive element (button, link, etc.) which will have a few props passed
 * @example
 * return (
 *  <PopOver
 *    trigger={`User {name}`}
 *  >
 *     <ComplexForm/>
 *  </PopOver>
 * )
 *
 * @param trigger {PropTypes.node | string} - A valid React node to be used as a trigger element
 * @param defaultOpen {boolean} - Should the popover be opened when it's rendered
 * @param className {string} - Custom classNames for parent element of popover (which includes the button and content)
 * @returns {JSX.Element}
 * @component
 */
const Popover = React.forwardRef((props, ref) => {
  const {
    // Base props
    children,
    trigger,
    triggerId,
    defaultOpen,
    // Classnames
    className,
    buttonClassName,
    contentClassName,
    // Accessibility props
    tabIndex,
    accessibility,
    accessibilityOmit,
    // Position
    position,
    arrowPosition,
    // Modifiers
    isNarrow,
    disabled,
    size,
    popOnHover,
    hideArrow,
    alwaysClose,
    withPortal,
    contentOffset,
    initialFocus,
    fallBackPlacements,
    delayMs,
    contentHoverInteraction,
    enableOnClickEvent,
    // Close button
    showCloseButton,
    closeButtonSize,
    // Callbacks
    onToggle,
    onOpen,
    onClose,
    onContentMount = () => {},
    closeOnBackwardsFocus,
    focusOrder,
    popoverTitle,
  } = props;

  // The internal state of current Popover
  const [state, setState] = useState({
    isOpen: defaultOpen,
    // Used for pointing arrow positioning
    arrowPosition: { x: 0, y: 0 },
    referenceWidth: 0,
    height: null,
  });

  const placement = useMemo(() => getPlacement(position), [position]);
  /**
   * Handle positioning of the popover
   */
  const nodeId = useFloatingNodeId();
  const { x, y, reference, floating, context } = useFloating({
    placement,
    strategy: 'absolute',
    open: state.isOpen,
    onOpenChange(isOpen) {
      // It checks first if the popover could be open and after applies the actual action
      if (disabled) return;
      // Run callback functions
      isFunction(onToggle) && onToggle(isOpen);
      const callback = isOpen ? onOpen : onClose;
      isFunction(callback) && callback();
      setState((prev) => ({ ...prev, isOpen }));
    },
    middleware: [
      offset({
        // Push popover 7px from the trigger element on both axis
        mainAxis: 7,
        alignmentAxis: -7,
      }),
      flip({
        fallbackPlacements: [
          ...fallBackPlacements,
          'top',
          'top-start',
          'top-end',
          'bottom',
          'bottom-start',
          'bottom-end',
        ],
        padding: 10,
      }),
      shift({
        padding: 20,
      }),
      applySize({
        apply(sizeProps) {
          // Always push changes after other changes were committed to state
          ReactDOM.flushSync(() => {
            const dy = Math.round(sizeProps.availableHeight, 2);
            // Get content positioning and sizes
            const r =
              sizeProps.elements.floating.firstChild.getBoundingClientRect();
            // Ensure that the floating elements is always visible and has a decent height
            // If the content of popover is bigger than the available space, set `max-height` to the available space
            const maxHeight =
              r.height > dy && dy > 20 ? dy : Math.round(r.height + 30);

            setState((prev) => ({
              ...prev,
              // Based on current floating element position, calculate the arrow position
              arrowPosition: getArrowPosition(sizeProps),
              width: r.width,
              height: maxHeight,
            }));
          });
        },
        padding: 10,
      }),
    ],
    whileElementsMounted: autoUpdate,
    nodeId,
  });

  // Handle popover interactions
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context, {
      toggle: true,
      ignoreMouse: popOnHover,
    }),
    useHover(context, {
      enabled: popOnHover,
      restMs: delayMs,
      handleClose: contentHoverInteraction && safePolygon(),
    }),
    useId(),
    useRole(context, { role: 'region' }),
    useDismiss(context, {
      bubbles: false,
      referencePress: false,
      outsidePress: true,
    }),
  ]);

  const handleOpen = () => context.onOpenChange(true);
  const handleClose = () => context.onOpenChange(false);
  // Allow parent components to control the Popover though the ref
  useImperativeHandle(ref, () => ({
    isOpen: state.isOpen,
    close: handleClose,
    open: handleOpen,
    toggle: state.isOpen ? handleClose : handleOpen,
    focus: () => {
      reference.current.focus();
    },
  }));

  /**
   * Build up the button props
   */
  const initialButtonProps = getReferenceProps({
    disabled,
    'data-test-id': 'pop_over_toggle',
    className: cn('popover__button', buttonClassName),
    ...accessibility,
    'aria-expanded':
      accessibility && 'aria-expanded' in accessibility
        ? accessibility['aria-expanded']
        : state.isOpen,
    id: useId(),
    tabIndex,
    // Prevent bubbling up events (click/enter/space) for nested popovers
    onClick(event) {
      event.stopPropagation();
      // By default, JAWS and NVDA converts key events into click events
      // In that case we should use onClick event
      if (enableOnClickEvent) {
        setState({ ...state, isOpen: !state?.isOpen });
      }
    },
    onKeyDown(event) {
      if (event.key === 'Enter' || event.key === ' ') {
        event.stopPropagation();
        event.preventDefault();
      }
    },
    onKeyUp(event) {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        event.stopPropagation();
      }
    },
  });
  const buttonProps = omit(initialButtonProps, [
    'aria-controls',
    'aria-expanded',
    ...accessibilityOmit,
  ]);
  /* End of button props */

  // Prevent bubbling up the clicks on popover content
  const idForAriaLabelledBy = triggerId || buttonProps?.id || null;
  const floatingProps = getFloatingProps({
    'aria-labelledby': idForAriaLabelledBy,
    onClick(event) {
      event.stopPropagation();
      setTimeout(() => {
        alwaysClose && handleClose();
      }, 1);
    },
  });

  // Close popover when is no longer in viewport
  useEffect(() => {
    if (!state.isOpen) return;
    // When the reference button is 40% visible or less, close the popover
    const options = { threshold: 0.4 };
    const observer = new IntersectionObserver(([entry]) => {
      if (!entry.isIntersecting) {
        context.events.emit('dismiss', {
          data: {
            returnFocus: {
              preventScroll: true,
            },
          },
        });

        handleClose();
      }
    }, options);

    observer.observe(context.refs.reference.current);
    return () => observer.disconnect();
  }, [state.isOpen]);

  useEffect(() => {
    if (disabled) {
      setState((currentState) => ({ ...currentState, isOpen: false }));
    }
  }, [disabled]);
  const finalVDir = context.placement.split('-')[0];
  const finalHDir = context.placement.split('-')[1];

  // Show close button whenever is requested
  const hasClose = closeButtonSize ? true : showCloseButton;

  // Gather all classes surrounding the popover
  const classNames = {
    // The container around the popover element
    outerContainer: cn(
      {
        popover: true,
        'popover--open': state.isOpen,
        'popover--closed': !state.isOpen,
        [`popover--${finalVDir}`]: true,
        'popover--hide-arrow': hideArrow,
      },
      className,
    ),
    // The container around the portal content
    popoverPortal: cn(
      {
        popover: true,
        'popover--open': state.isOpen,
        'popover--closed': !state.isOpen,
        [`popover--${finalVDir}`]: true,
        'popover--hide-arrow': hideArrow,
        'popover--portal': withPortal,
      },
      className,
    ),
    // Popover content
    popoverContent: cn(
      {
        popover__content: true,
        [`popover__content--${finalVDir}`]: finalVDir,
        [`popover__content--${finalHDir}`]: finalHDir,
        'popover__content--is-narrow': isNarrow,
        'popover__content--w-auto': size === 'auto',
        [`popover__content--${size}`]: size,
        'cursor-default': true,
      },
      contentClassName,
    ),
    // The close button from top right corner
    closeButton: cn('!outline-none', {
      popover__close: true,
      'popover__close--ml-auto': true,
      [`popover__close--${closeButtonSize}`]: closeButtonSize,
    }),
    arrow: cn({
      popover__arrow: true,
      [`popover__arrow--${finalVDir}`]: finalVDir,
    }),
  };

  const content = state.isOpen ? (
    <PopoverContentElement
      context={context}
      initialFocus={initialFocus}
      classNames={classNames}
      floating={floating}
      contentOffset={contentOffset}
      floatingProps={floatingProps}
      closeOnBackwardsFocus={closeOnBackwardsFocus}
      withPortal={withPortal}
      arrowPosition={arrowPosition || state.arrowPosition}
      maxHeight={state.height}
      x={x}
      y={y}
      hasClose={hasClose}
      handleClose={handleClose}
      hideArrow={hideArrow}
      onMount={onContentMount}
      focusOrder={focusOrder}
      popoverTitle={popoverTitle}
    >
      {children}
    </PopoverContentElement>
  ) : null;

  return (
    <div className={classNames.outerContainer}>
      {/* The button which opens the popover */}
      {isFunction(trigger) ? (
        React.cloneElement(
          trigger(buttonProps, state.isOpen, reference, buttonClassName),
        )
      ) : (
        <div {...buttonProps} ref={reference}>
          {React.isValidElement(trigger)
            ? React.cloneElement(trigger, {
                open: state.isOpen,
                'aria-controls': floatingProps?.id || '',
                'aria-expanded': state?.isOpen || false,
                tabIndex: '0',
              })
            : trigger}
        </div>
      )}
      {/* The button which opens the popover */}
      {/* ↓ Some elements should end-up in a portal outside the main content */}
      {withPortal ? (
        <FloatingPortal id={nodeId} root={document.querySelector('#modal')}>
          {content}
        </FloatingPortal>
      ) : (
        content
      )}
      {/* ↑ Some elements have to be rendered together with their popover */}
    </div>
  );
});

Popover.displayName = 'Popover';
Popover.propTypes = {
  // The element which will open the popover
  trigger: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.func,
  ]).isRequired,
  triggerId: PropTypes.string,
  // Should the popover be opened when it's rendered
  defaultOpen: PropTypes.bool,
  // Callback when popover is toggled
  onTrigger: PropTypes.func,
  // Callback when popover is opened
  onOpen: PropTypes.func,
  // Callback when popover is closed
  onClose: PropTypes.func,
  // Triggers when popover content added to the DOM, the argument has content ref
  onContentMount: PropTypes.func,
  // Preferential positioning of the popover
  // ('top' | 'bottom') could be paired with one of ('start' | 'center' | 'end')
  // eslint-disable-next-line react/require-default-props
  position: PropTypes.oneOf([
    'left',
    'start',
    'center',
    'end',
    'top',
    'top-start',
    'top-center',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-center',
    'bottom-end',
    'right',
    'right-start',
    'right-center',
    'right-end',
  ]),
  // Sets the size of popover content
  size: PropTypes.oneOf(['auto', 'narrow', 'normal', 'medium', 'wide', 'full']),
  fallBackPlacements: PropTypes.array,
  // Offset of the popover content from the reference element
  contentOffset: PropTypes.string, // '0 -3rem',
  // Control `tabIndex` of the button
  // Usually used when the element should disappear from the tab order
  tabIndex: PropTypes.number,
  // Pass accessibility attributes to the button
  // Trigger button accessibility attributes
  accessibility: PropTypes.shape({
    'aria-label': PropTypes.string,
    'aria-describedby': PropTypes.string,
  }),
  // Show a close button in the top right corner
  showCloseButton: PropTypes.bool,
  // The size of the close button
  closeButtonSize: PropTypes.oneOf(['small', 'medium']),
  // Show popover on hover over the trigger
  popOnHover: PropTypes.bool,
  // Hide the pointing arrow
  hideArrow: PropTypes.bool,
  // Always close the popover
  alwaysClose: PropTypes.bool,
  // Remove padding from content
  isNarrow: PropTypes.bool,
  // Render popover outside the trigger element context, in `modal` div.
  withPortal: PropTypes.bool,
  /**
   * https://floating-ui.com/docs/floatingfocusmanager#initialfocus
   * Should the popover automatically focus on the first tabbable element when opened.
   * 0 is the default in the documentation i.e. yes. -1 means don't focus on anything.
   */
  initialFocus: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  closeOnBackwardsFocus: PropTypes.bool,
  // 'FloatingFocusManager' component from floating-ui requires the tabbed orders of popover
  // elements it was opened. With this prop we can override it when necessary.
  focusOrder: PropTypes.array,
};

Popover.defaultProps = {
  triggerId: null,
  defaultOpen: false,
  size: 'auto',
  contentOffset: '0 0',
  accessibility: {},
  accessibilityOmit: [],
  tabIndex: 0,
  ariaDescribedBy: '',
  contentClassName: '',
  showCloseButton: false,
  closeButtonSize: null,
  popOnHover: false,
  hideArrow: false,
  alwaysClose: false,
  isNarrow: false,
  withPortal: false,
  contentHoverInteraction: false,
  onTrigger: () => {},
  onOpen: () => {},
  onClose: () => {},
  onContentMount: () => {},
  initialFocus: 0,
  fallBackPlacements: [],
  closeOnBackwardsFocus: false,
  focusOrder: ['content', 'reference', 'floating'],
  popoverTitle: null,
};

export default Popover;
