import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'legacy/packages/ft-cb';

import './TopBarContainer.scss';

const TopBarContainer = (props) => {
  const showPreAPIcon = props.showPreapBranding && props.isPreApSubject;
  return (
    <div className="top_bar_wrapper">
      <header
        className={`top_bar ${
          props.scrolled && props.shouldCollapse ? 'scrolled' : ''
        }`}
      >
        <div className="top_bar_branding">
          <a
            className="top_bar_branding__link"
            href="http://www.collegeboard.org"
          >
            {props.logo}
          </a>
        </div>
        <div className="top_bar_ap_classroom">
          <Icon
            name={`${showPreAPIcon ? 'preap_classroom' : 'ap_classroom'}`}
            className="ap_classroom"
            height="18"
          />
        </div>
        <div className="top_bar_menus">{props.children}</div>
      </header>
    </div>
  );
};

TopBarContainer.displayName = 'TopBarContainer';

TopBarContainer.propTypes = {
  logo: PropTypes.object,
};

export default TopBarContainer;
