import { ASSIGNMENT_STATUS_CRITERIA } from 'legacy/src/constants/assignment';
import get from 'lodash/get';
import set from 'lodash/set';
import isObject from 'lodash/isObject';
import throttle from 'lodash/throttle';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import isFunction from 'lodash/isFunction';
import transform from 'lodash/transform';
import {
  MATHEMATICS_CHARACTER_MAP_ICON,
  MATHEMATICS_CHARACTER_MAPS,
} from 'legacy/src/components/learnosity/utils/constants';
import { notify } from 'legacy/packages/ft-cb';
import { ATTESTATION_PREFERENCE } from 'legacy/src/constants/types';
import { SUBJECT_TYPE_VIEW } from 'legacy/src/constants/config';
import { MathematicsSubjects } from 'chameleon/src/constants/subjects';
import { ALPHABET } from 'legacy/src/utility/utils';

const { keys, values } = Object;

// question helpers
export const findResponseIndex = (response, options) =>
  options.findIndex(
    (option) => response.value && response.value[0] === option.value,
  );

export const validateQuestion = (question) => {
  question.validate({ showCorrectAnswers: true });
};

export const removeCriteriaFromRubricResponse = (rubricResponses = {}) =>
  transform(
    rubricResponses,
    (allResponses, resultsForStudent, studentId) =>
      // eslint-disable-next-line no-param-reassign
      (allResponses[studentId] = transform(
        resultsForStudent,
        (response, score, rubric) =>
          (response[rubric] = score.score ? score.score : score),
      )),
  );

// rubrics
export const hasAllRubricsFilled = (scoringRubricItemsApp) => {
  const responses = scoringRubricItemsApp.getResponses();
  const rubricItems = scoringRubricItemsApp.getItems();

  const hasAllResponses = keys(rubricItems).length === keys(responses).length;
  const isAllResponsesScored = values(responses).every((response) => {
    if (response) {
      if (!response.value) {
        // {value: null} or value is undefined
        return false;
      }
      if (isObject(response) && isObject(response.value)) {
        // {value: {score: "1", ...}}
        return Boolean(response.value.score);
      }
      // {value: "1"}
      return Boolean(response.value);
    }
  });

  return hasAllResponses && isAllResponsesScored;
};

export const getFinishedPercent = (progress) => {
  const notStarted = progress.notStarted || progress.not_started || 0;
  const started = progress.started || 0;
  const submitted = progress.submitted || 0;
  const scoring = progress.scoring || 0;
  const scored = progress.scored || 0;
  const studentScored = progress.studentScored || progress.student_scored || 0;
  const teacherAndStudentScored =
    progress.teacherAndStudentScored ||
    progress.teacher_and_student_scored ||
    0;
  const complete = progress.complete || 0;
  const readyToScore = progress.readyToScore || progress.ready_to_score || 0;

  const scoredSubmissions =
    complete + scored + studentScored + teacherAndStudentScored;
  const totalSubmissions =
    notStarted +
    started +
    submitted +
    scoring +
    scored +
    studentScored +
    teacherAndStudentScored +
    complete +
    readyToScore;
  if (totalSubmissions === 0) {
    return 0;
  }
  return (scoredSubmissions / totalSubmissions) * 100;
};

export const isAssignmentInProgress = (progress) =>
  getFinishedPercent(progress) < ASSIGNMENT_STATUS_CRITERIA;

export function saveAuthorInstance(instance) {
  instance.save();
  // the saving is synchronous, but the api request is a-synchronous.
  // we need to reliably wait for the saving to end in order to set next widget (question)
  return new Promise((resolve, reject) => {
    instance.on('save:success', () => {
      resolve();
      instance.off('save:success');
      instance.off('save:error');
    });
    instance.on('save:error', (e) => {
      reject(e);
      instance.off('save:error');
      instance.off('save:success');
    });
  });
}

export const flattenTags = (tagsToFlatten) => {
  if (!tagsToFlatten || !tagsToFlatten.length) {
    return [];
  }

  return [
    ...tagsToFlatten.map((tagToFlatten) => ({
      ...omit(tagToFlatten, ['tags']),
    })),
    ...flattenTags(
      tagsToFlatten
        .map((tag) => tag.tags)
        .reduce((curr, next) => [...curr, ...next], []),
    ),
  ];
};

export const mapTagsToCategories = (tags) => {
  if (!tags || !tags.length) {
    return {};
  }

  let mappedTags = {};
  tags.forEach((tag) => {
    const tagId = parseInt(tag.id, 10);
    tag.categories.forEach((category) => {
      const categoryId = parseInt(category.id, 10);
      if (categoryId in mappedTags) {
        mappedTags[categoryId].push(tagId);
      } else {
        mappedTags[categoryId] = [tagId];
      }
    });
  });

  return mappedTags;
};

export function createResponseAnalysis(
  learnosityReportsResponseAnalysisData,
  reference,
) {
  if (
    !learnosityReportsResponseAnalysisData ||
    isEmpty(learnosityReportsResponseAnalysisData)
  ) {
    return {};
  }

  const referenceItems =
    learnosityReportsResponseAnalysisData &&
    learnosityReportsResponseAnalysisData[0].data.items &&
    learnosityReportsResponseAnalysisData[0].data.items.filter(
      (item) => item.item_id === reference,
    );

  const referenceQuestions =
    learnosityReportsResponseAnalysisData &&
    learnosityReportsResponseAnalysisData[0].data.questions &&
    learnosityReportsResponseAnalysisData[0].data.questions.filter(
      (question) => question.item_id === reference,
    );

  return {
    items: referenceItems,
    questions: referenceQuestions,
    sessions:
      learnosityReportsResponseAnalysisData &&
      learnosityReportsResponseAnalysisData[0].data.sessions,
  };
}

export function getStudentResponseForItem(
  responses,
  reference,
  learnosityQuestion,
) {
  // I think we can just get correct response here with learnosityQuestion.getResponse()...
  if (!learnosityQuestion) {
    return null;
  }

  // transform A,B back to i1...i2
  // some of the questions values starts from i0, some from i1, 1, 0
  // use real options from learnosity question to map answers (A, B, C) back to values

  const processedOptions = isFunction(learnosityQuestion.getProcessedOptions)
    ? learnosityQuestion.getProcessedOptions()
    : [];

  // if this not mcq and haapens to have no responses
  if (isEmpty(processedOptions) || isEmpty(responses)) {
    return null;
  }

  return transform(
    responses,
    (result, value, key) => {
      // key is studentId, value responses
      if (!value) return result;

      const responsesPerItem = value && value[reference];
      if (Array.isArray(responsesPerItem)) {
        result[key] = responsesPerItem.map((response) => {
          const index = ALPHABET.indexOf(response);
          return processedOptions[index] && processedOptions[index].value;
        });
      }

      return result;
    },
    {},
  );
}

export const PROVIDER_MODES = {
  edit: 'edit',
  preview: 'preview',
};

export function switchMode(containerId, mode = PROVIDER_MODES.preview) {
  const wrapper = containerId ? document.getElementById(containerId) : document;
  // we imitate click on edit | preview label to switch between modes
  // as there is API to do so, but it does not do what supposed to
  // https://reference.learnosity.com/author-api/methods#changeItemMode does nothing

  const buttonFinder = (dataAttr) =>
    // using reverse so the last click would in on the first AuthorAPI instance and UI
    // won't scroll to the bottom.
    Array.from(wrapper.getElementsByClassName(dataAttr)).slice().reverse();

  const editButtons = buttonFinder('lrn-qe-toggle-left');
  const previewButtons = buttonFinder('lrn-qe-toggle-right');

  // Learnosity needs 200 ms timeout between clicks in order to switch each
  // AuthorAPI instance correctly (shared passage has issues with permanently displaying
  // "Show Correct answers" otherwise.
  const nonBlockingTimeout = 200;

  const throttledSwitch = throttle(
    (button) => button.click(),
    nonBlockingTimeout,
  );

  (mode === PROVIDER_MODES.preview ? previewButtons : editButtons).map(
    (button) => {
      throttledSwitch(button);
    },
  );
}

export const goToQuestionWidget = (app) => {
  const item = app.getItem();
  if (get(item, 'questions.length')) {
    const itemQuestion = item.questions[0];
    app.navigate(
      `items/${item.item.reference}/widgets/${itemQuestion.reference}`,
    );
  } else {
    notify('Error loading question, please try again', { timeout: 5 });
  }
};

export const getPassageFromItem = (item) => {
  const passage = get(item, 'features[0]');
  if (passage && passage.type === 'sharedpassage') {
    return passage;
  }
};

export const goToPassageWidget = (app) => {
  const item = app.getItem();
  const passage = getPassageFromItem(item);

  if (passage) {
    app.navigate(`items/${item.item.reference}/widgets/${passage.reference}`);
  }
};

export const setWidgetFields = (editorApp, fields = {}) => {
  Object.keys(fields).forEach((key) => {
    const fieldValue = fields[key];
    const attr = editorApp.attribute(key);
    attr && attr.setValue(fieldValue);
  });
};

export const findSubjectByMasterSubjectId = (subjects, masterSubjectId) =>
  subjects &&
  masterSubjectId &&
  subjects.find(
    (subject) =>
      parseInt(subject.masterSubjectId, 10) === parseInt(masterSubjectId, 10),
  );

const findSubject = (subjects, masterSubjectId, subjectType) =>
  subjects &&
  subjects.find(
    (subject) =>
      subject.type === subjectType &&
      parseInt(subject.masterSubjectId, 10) === parseInt(masterSubjectId, 10),
  );

export const findQBSubject = (currentTeacherSubjects, masterSubjectId) =>
  findSubject(
    currentTeacherSubjects,
    masterSubjectId,
    SUBJECT_TYPE_VIEW.QUESTION_BANK,
  );

// This is the PREAP version of findQBSubject
export const findPREAPSubject = (currentTeacherSubjects, masterSubjectId) =>
  findSubject(currentTeacherSubjects, masterSubjectId, SUBJECT_TYPE_VIEW.PREAP);

export const acceptedAttestation = (me, qbSubject) => {
  const attestationPreference = findAttestationPreference(me, {
    initId: qbSubject,
  });
  return (
    (attestationPreference && attestationPreference.value === 'true') || false
  );
};

export const findAttestationPreference = (me, qbSubject) =>
  me &&
  me.preferences &&
  me.preferences.find(
    ({ preference, contextId }) =>
      preference === ATTESTATION_PREFERENCE &&
      parseInt(contextId, 10) === parseInt(qbSubject.initId, 10),
  );

export const shouldShowAttestationDialog = (
  showAttestationFeatureFlag,
  currentTeacherSubjects,
  me,
  isAlreadyShown,
  masterSubjectId,
) => {
  const qbSubject = findQBSubject(currentTeacherSubjects, masterSubjectId);
  if (!qbSubject) return false;
  const { containsSecureQuestions } = qbSubject;
  const attestationPreferenceForSubject = findAttestationPreference(
    me,
    qbSubject,
  );
  const attestationState =
    attestationPreferenceForSubject &&
    [true, 'true'].includes(attestationPreferenceForSubject.value);

  return (
    showAttestationFeatureFlag &&
    containsSecureQuestions &&
    !attestationState &&
    !isAlreadyShown
  );
};

export const hasContent = (html) =>
  new DOMParser().parseFromString(html, 'text/html').documentElement
    .textContent;

export const hasLearnosityQuestionAllAnswersCorrect = (container) =>
  container.querySelectorAll('.lrn_not_attempted').length === 0 &&
  container.querySelectorAll('.lrn_incorrect').length === 0;

export const customCbRubricsConfSetter = ({
  data = {},
  mainLabel = 'Teacher Score',
  assignment,
  progressPerStudent = {},
}) => {
  const { studentScored, teacherAndStudentScored, scored } =
    progressPerStudent || {};
  // pass data to custom_cb_rubric with global object
  window.customCbRubricsConf = {
    ...data,
    mainLabel,
    showStudentResults: Boolean(studentScored || teacherAndStudentScored),
    showTeacherResults: Boolean(scored || teacherAndStudentScored),
    showCriteriaLabel: Boolean(
      get(assignment, 'studentScoring') || get(assignment, 'hasStudentScores'),
    ),
  };
};

export const getMathematicsSubjectsConfig = (subjectId) =>
  MathematicsSubjects.includes(subjectId)
    ? {
        questions_api_init_options: {
          labelBundle: {
            characterMathMapTitle: '',
            characterMapMathIcon: MATHEMATICS_CHARACTER_MAP_ICON,
            characterMapMathCharacters: MATHEMATICS_CHARACTER_MAPS,
          },
        },
      }
    : null;

export const deserialiseScoringRubricResponses = (
  scoringRubricResponses,
  studentId,
) => {
  if (!scoringRubricResponses || isEmpty(scoringRubricResponses)) {
    return;
  }
  // serialize data back to the format expected by custom_cb_rubric.js
  return set(
    {},
    '0.data.questions',
    transform(
      scoringRubricResponses[studentId],
      (result, value, key) => {
        result.push({
          item_id: key,
          ...set({}, 'permutations[0].response.value', value),
        });
      },
      [],
    ),
  );
};
